import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Auth/Login'
import Dashboard from './Screens/Dashboard';
import Agents from './Screens/Agents';
import Users from './Screens/Users'
import Fees from './Screens/Fees';
import Rates from './Screens/Rates';
import Risk from './Screens/Risk';
import Add from './Screens/Add';
import ExchangeRates from './Screens/ExchangeRates';
import TransSatus from './Screens/TransSatus';
import HighRiskTrans from './Screens/HighRiskTrans';
import SuspendedTrans from './Screens/SuspendedTrans';
import Regulatory from './Screens/Regulatory';
import Complience from './Screens/Complience';
import Settings from './Screens/Settings';
import Employees from './Screens/Employees';
import MNavComp from './Components/ManagerComp/MNavComp';
import ManagerDash from './Screens/Manager/ManagerDash';
import Logout from './Auth/Logout';
import Nav from './Components/Nav';
import Transactions from './Screens/Transactions';
import Clients from './Screens/Clients';
import Deposits from './Screens/Deposits';
import Initiated from './Screens/Initiated';
import InnerBank from './Screens/InnerBank';
import ViewInner from './Screens/ViewInner';
import TransHistory from './Screens/TransHistory';
import Floats from './Screens/Floats';
import Limit from './Screens/Limit';
import SanctionedList from './Screens/SanctionedList';
import Rbz from './Screens/Rbz';
import NewTop from './Components/NewTop';



function App() {



  return (
    <Router>
    <Routes >
     <Route path="/login" element={<Login />} />

     <Route path="" element={<NewTop/>}>
        <Route path="*" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/agents" element={<Agents/>} />
        <Route path="/users" element={<Users/>} />
        <Route path="/fees" element={<Fees/>} />
        <Route path="/rates" element={<Rates/>} />
        <Route path="/risk" element={<Risk/>} />
        <Route path="/add" element={<Add/>} />
        <Route path="/exchangeRates" element={<ExchangeRates/>} />
        <Route path="/transactions" element={<Transactions/>} />
        <Route path="/transStatus" element={<TransSatus/>} />
        <Route path="/highRisk" element={<HighRiskTrans/>} />
        <Route path="/suspended" element={<SuspendedTrans/>} />
        <Route path="/regulatory" element={<Regulatory/>} />
        <Route path="/complience" element={<Complience/>} />
        <Route path="/settings" element={<Settings/>} />
        <Route path="/employees" element={<Employees/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/clients" element={<Clients/>} />
        <Route path="/deposits" element={<Deposits/>} />
        <Route path="/initiated" element={<Initiated/>} />
        <Route path="/inner" element={<InnerBank/>} />
        <Route path="/viewInner" element={<ViewInner/>} />
        <Route path="/trans-hist" element={<TransHistory/>} />
        <Route path="/floats" element={<Floats/>} />
        <Route path="/set-limit" element={<Limit/>} />
        <Route path="/sanction" element={<SanctionedList/>} />
        <Route path="/rbz" element={<Rbz/>} />





        {/* manager */}
        <Route path="/managerDash" element={<ManagerDash/>} />
     </Route>


    </Routes>
  </Router>
  );
}

export default App;
