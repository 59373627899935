import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'; // Importing the icon

import CreateBranch from './CreateBranch';
import handleCreateBank from './ManageBankAccounts';
import ManageCurrencies from './handleAddCurrency';
import NkoloziAccounts from './NkoloziAccounts';
import TransactionFees from './TransactionFees';
import AdminRoles from './AdminRoles';
import InnerBank from '../Screens/InnerBank';
import ViewInnerBannk from '../Screens/ViewInner';
import Password from './PasswordComp'
import { useNavigate } from 'react-router-dom';
import PasswordComp from './PasswordComp';

function SettingsComponent() {
  const navigate = useNavigate();
  
  return (
    <main id="main" class="main">
      <Container
        sx={{
          marginTop: '5%',
          marginLeft: { xs: '5%', sm: '10%', md: '20%' },
          width: { xs: '90%', sm: '80%', md: '70%' },
          maxHeight: '80vh',
          overflow: 'auto',
          '@keyframes fade-in': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
          animation: 'fade-in 0.5s ease-in-out',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Branches <AccountTreeIcon />
          </Typography>
          <CreateBranch />
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Banks <AccountBalanceIcon />
          </Typography>
          {handleCreateBank()}
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Currencies <CurrencyExchangeIcon />
          </Typography>
          {ManageCurrencies()}
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Transaction Fees <CurrencyExchangeIcon />
          </Typography>
          <TransactionFees />
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Admin Roles <SupervisorAccountIcon />
          </Typography>
          <AdminRoles />
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Inner Branch Transfers <TransferWithinAStationIcon />
          </Typography>
          <InnerBank />
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            View Inner Branch Transfers
          </Typography>
          <ViewInnerBannk />
        </Paper>
        <Paper
          sx={{
            padding: '20px',
            marginBottom: '20px',
            width: '100%',
            '@keyframes slide-up': {
              '0%': {
                transform: 'translateY(20px)',
                opacity: 0,
              },
              '100%': {
                transform: 'translateY(0)',
                opacity: 1,
              },
            },
            animation: 'slide-up 0.5s ease-in-out',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Update Password <SettingsIcon />
          </Typography>
          {/* Add other settings buttons or inputs here */}
          <PasswordComp/>
          
        </Paper>
      </Container>
    </main>
  );
}

export default SettingsComponent;
