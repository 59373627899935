import React from 'react'
import SideNav from '../Components/SideNav'
import TransactionsComp from '../Components/TransactionsComp'
import NewSide from '../Components/NewSide'

function Transactions() {
  return (
    <>
   <NewSide/>
   <TransactionsComp/>
   </>
  )
}

export default Transactions
