import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Pagination, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { Paper, Typography } from '@mui/material';

const TransactionsComp = () => {
  const [transactions, setTransactions] = useState([]);
  const [clients, setClients] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // New state for the search term
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem('admin_token');

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const [transactionsResponse, clientsResponse, agentsResponse, currenciesResponse] = await Promise.all([
          axios.get('https://api.nkolozi.com/api/Admin/transactions', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/clients', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/agents', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/currencies', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        setTransactions(transactionsResponse.data.data);
        setClients(clientsResponse.data.data);
        setAgents(agentsResponse.data.data);
        setCurrencies(currenciesResponse.data.data);
        setTotalPages(Math.ceil(transactionsResponse.data.data.length / 100)); // Changed to 100 transactions per page
      } catch (error) {
        console.error('Error fetching data:', error);
        
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [token]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUserName = (userId) => {
    const userIdStr = String(userId);
    const client = clients.find(client => String(client.id) === userIdStr);
    if (client) return `${client.name} ${client.surname}`;
    
    const agent = agents.find(agent => String(agent.id) === userIdStr);
    if (agent) return `${agent.name} ${agent.surname}`;
  
    return 'Unknown User';
  };

  const getCurrencyName = (currencyId) => {
    const currency = currencies.find(currency => currency.id === currencyId);
    return currency ? currency.name : 'Unknown Currency';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page on a new search
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const userName = getUserName(transaction.users_id).toLowerCase();
    return userName.includes(searchTerm.toLowerCase());
  });

  // Update total pages whenever filtered results change
  useEffect(() => {
    setTotalPages(Math.ceil(filteredTransactions.length / 100));
    // Reset to first page if current page is out of bounds
    if (currentPage > Math.ceil(filteredTransactions.length / 100)) {
      setCurrentPage(1);
    }
  }, [filteredTransactions.length, currentPage]);

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * 100; // Changed to 100
    const endIndex = startIndex + 100; // Changed to 100
    return filteredTransactions.slice(startIndex, endIndex).map((transaction) => (
      <tr key={transaction.id}>
        <td>{transaction.transaction_id}</td>
        <td>{getUserName(transaction.users_id)}</td>
        <td>{transaction.initial_transfer_amount}</td>
        <td>{transaction.transaction_fee}</td>
        <td>{transaction.total_send_amount}</td>
        <td>{getCurrencyName(transaction.currency_id)}</td>
        <td>
          <span style={{
            backgroundColor:
              transaction.status === 'initiated' ? 'lime' :
              transaction.status === 'approved' ? 'green' :
              transaction.status === 'suspended' ? 'orange' :
              transaction.status === 'rejected' ? 'red' :
              transaction.status === 'completed' ? 'blue' :
              'black'
          }}>
            {transaction.status}
          </span>
        </td>
        <td>{formatDate(transaction.created_at)}</td>
        <td>{transaction.transaction_reason}</td>
        <td>
          <span style={{
              color:
                transaction.flag === 'normal' ? 'blue' :
                transaction.flag === 'structured' ? 'yellow' :
                transaction.flag === 'unusual' ? 'red' :
                'black' 
            }}>
              {transaction.flag}
          </span>
        </td>
      </tr>
    ));
  };

  return (
    <main id="main" className="main" style={{ 
      height: 'calc(100vh - 60px)',
      overflowY: 'auto',
      marginTop: '60px'
    }}>
      <div style={{ 
        padding: '20px',
      }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Paper
            sx={{
              padding: '3px 10px', 
              marginBottom: '20px',
              width: 'fit-content',
              backgroundColor: '#c5401f',
              '@keyframes slide-up': {
                '0%': {
                  transform: 'translateY(20px)',
                  opacity: 0,
                },
                '100%': {
                  transform: 'translateY(0)',
                  opacity: 1,
                },
              },
              animation: 'slide-up 0.5s ease-in-out',
            }}
          >
            <Typography variant="h5" gutterBottom style={{ color: 'white' }}>
              All Transactions
            </Typography>
          </Paper>
        </div>

        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by Name or Surname"
            aria-label="Search by Name or Surname"
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={handleSearch}
          />
        </InputGroup>

        <div style={{ flex: 1, overflowY: 'auto', minHeight: 0 }}>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead style={{ backgroundColor: '#f8f9fa' }}>
                    <tr>
                      <th style={{ color: 'black' }}>Transaction ID</th>
                      <th style={{ color: 'black' }}>User</th>
                      <th style={{ color: 'black' }}>Initial Amount</th>
                      <th style={{ color: 'black' }}>Transaction Fee</th>
                      <th style={{ color: 'black' }}>Total Amount</th>
                      <th style={{ color: 'black' }}>Currency</th>
                      <th style={{ color: 'black' }}>Status</th>
                      <th style={{ color: 'black' }}>Created At</th>
                      <th style={{ color: 'black' }}>Reason</th>
                      <th style={{ color: 'black' }}>Flag</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows()}
                  </tbody>
                </Table>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Pagination className="justify-content-center">
                  <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                  <Pagination.Item active>{currentPage}</Pagination.Item>
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default TransactionsComp;
