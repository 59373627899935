import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Modal, Button, Spinner, Alert, Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AgentsComp() {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentAccounts, setAgentAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgents = async () => {
      const token = localStorage.getItem('admin_token');
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/agents', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        setAgents(response.data.data);
        console.log(response)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setLoading(false);
      }
    };

    const fetchCurrencies = async () => {
      const token = localStorage.getItem('admin_token');
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/currencies', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        setCurrencies(response.data.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    fetchAgents();
    fetchCurrencies();
  }, []);

  const getCurrencyName = (currencyId) => {
    const currency = currencies.find((c) => c.id === parseInt(currencyId));
    return currency ? currency.name : 'Unknown';
  };

  useEffect(() => {
    
    const token = localStorage.getItem('admin_token');

    // Define the API URL
    const url = 'https://api.nkolozi.com/api/Admin/branchs';

    // Fetch the data when the component mounts
    const fetchBranches = async () => {
      try {
        // Include the Authorization header with Bearer token
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Log the response data to the console
        console.log('Branches data:', response.data);
        setBranches(response.data.data);
      } catch (error) {
        console.error('Error fetching branches:', error);
        // Handle specific error cases if necessary (e.g., token expired, server error, etc.)
      }
    };

    fetchBranches();
  }, []);

  const handleAgentClick = async (agent) => {
    const token = localStorage.getItem('admin_token');
    try {
      const response = await axios.get(`https://api.nkolozi.com/api/Admin/get-agent-accounts/${agent.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setSelectedAgent(agent);
      setAgentAccounts(response.data.agent_accounts); // Update state with fetched accounts
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching agent accounts:', error);
      setAlert({ show: true, message: 'Failed to load agent accounts. Please try again.', variant: 'danger' });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAgent(null);
    setAgentAccounts([]); // Clear accounts when closing the modal
  };

  const handleAction = async (url) => {
    const token = localStorage.getItem('admin_token');
    try {
      await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setAlert({ show: true, message: 'Action completed successfully!', variant: 'success' });
    } catch (error) {
      console.error('Error performing action:', error);
      setAlert({ show: true, message: 'Action failed. Please try again.', variant: 'danger' });
    }
  };

  const handleDeleteAgentClick = (agent) => {
    setSelectedAgent(agent);
    setSelectedBranch(agent.branch_id);
    setShowDeleteModal(true);
  };console.log("Selected",selectedAgent)

  const handleUpdateAgentClick = (agent) => {
    setSelectedAgent(agent);
    setSelectedBranch(agent.branch_id);
    setShowUpdateModal(true);
  };console.log("Selected",selectedAgent)

  const handleUpdateBranch = async () => {
    const token = localStorage.getItem('admin_token');
    try {
      await axios.post(`https://api.nkolozi.com/api/Admin/update-agent`,
        { new_branch_id: selectedBranch, agent_id: selectedAgent.id },
        { headers: { 'Authorization': `Bearer ${token}` } },
        console.log("token", token)
      );
      setAlert({ show: true, message: 'Branch updated successfully!', variant: 'success' });
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating branch:', error);
      setAlert({ show: true, message: 'Failed to update branch.', variant: 'danger' });
    }
  };

  const handleDeleteAgent = async () => {
    const token = localStorage.getItem('admin_token');
    try {
      await axios.get(`https://api.nkolozi.com/api/Admin/delete-agent/${selectedAgent.id}'`,
        { headers: { 'Authorization': `Bearer ${token}` } },
        console.log("token", token)
      );
      setAlert({ show: true, message: 'Agent deleted successfully!', variant: 'success' });
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting agent:', error);
      setAlert({ show: true, message: 'Failed to delete branch.', variant: 'danger' });
      setShowDeleteModal(false);
    }
  };

  const handleBlock = (id) => handleAction(`https://api.nkolozi.com/api/Admin/block-user/${id}`);
  const handleUnblock = (id) => handleAction(`https://api.nkolozi.com/api/Admin/un-block-user/${id}`);
  const handleActivate = (id) => handleAction(`https://api.nkolozi.com/api/Admin/activate-client/${id}`);

  return (
    <main id="main" className="main">
      <div className="container-fluid p-0">
        <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
          <h1>Agents</h1>
          <div className="d-flex gap-2 flex-wrap">
            <Button variant="warning" onClick={() => navigate('/set-limit')}>
              Set Cashin Limit
            </Button>
            <Button variant="primary" onClick={() => navigate('/floats')}>
              Agents Float
            </Button>
          </div>
        </div>
        {alert.show && (
          <Alert variant={alert.variant} onClose={() => setAlert({ show: false, message: '', variant: '' })} dismissible>
            {alert.message}
          </Alert>
        )}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <div className="table-responsive">
            <Table striped bordered hover className="table-sm">
              <thead>
                <tr>
                  <th>Reg_id</th>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Status</th>
                  <th>Phone</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {agents.map(agent => (
                  <tr key={agent.id} onClick={(e) => {
                    if (!e.target.closest('.dropdown')) {
                      handleAgentClick(agent);
                    }
                  }} style={{ cursor: 'pointer' }}>
                    <td>{agent.reg_id}</td>
                    <td style={{color:"blue"}}>{agent.name}</td>
                    <td>{agent.surname}</td>
                    <td>{agent.status}</td>
                    <td>{agent.phone1}</td>
                    <td>{agent.city}</td>
                    <td>{agent.country}</td>
                    <td>
                      <Dropdown onClick={(e) => e.stopPropagation()}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleBlock(agent.id); }}>Block</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleUnblock(agent.id); }}>Unblock</Dropdown.Item>
                          <Dropdown.Item onClick={(e) => { e.stopPropagation(); handleActivate(agent.id); }}>Activate</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleUpdateAgentClick(agent)}>Update Agent</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteAgentClick(agent)}>Delete Agent</Dropdown.Item>

                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {selectedAgent && (
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Agent</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Are you sure you want to delete the agent, <strong>{selectedAgent.name} {selectedAgent.surname}</strong></h4>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteAgent} disabled={!selectedBranch}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Update Agent Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="branchSelect">
                <Form.Label>Select Branch</Form.Label>
                <input type='hidden' name = "agent_id" value={selectedAgent}/>
                <Form.Control
                  as="select" 
                  value={selectedBranch || ""} 
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  style={{ color: selectedBranch ? "#000" : "#888" }}
                >
                  <option value="" disabled>Select a branch</option>
                  {branches && branches.length > 0 ? (
                    branches.map(branch => (
                      <option className='text-dark' key={branch.id} value={branch.id}>
                        {branch.branch_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No branches available</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateBranch} disabled={!selectedBranch}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {selectedAgent && (
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Agent Accounts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container-fluid p-0">
                <h5>Agent Details:</h5>
                <p><strong>Reg ID:</strong> {selectedAgent.reg_id}</p>
                <p><strong>Name:</strong> {selectedAgent.name}</p>
                <p><strong>Surname:</strong> {selectedAgent.surname}</p>
                <hr />
                <h5>Accounts:</h5>
                {agentAccounts.length > 0 ? (
                  <div className="table-responsive">
                    <Table striped bordered className="table-sm">
                      <thead>
                        <tr>
                          <th>Currency</th>
                          <th>Current Balance</th>
                          <th>Available Balance</th>
                          <th>Float Limit</th>
                          <th>Float Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentAccounts.map(account => (
                          <tr key={account.id}>
                            <td>{getCurrencyName(account.currency_id)}</td>
                            <td>{account.current_balance}</td>
                            <td>{account.available_balance}</td>
                            <td>{account.float_limit}</td>
                            <td>{account.float_balance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <p>No accounts available for this agent.</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </main>
  );
}

export default AgentsComp;
