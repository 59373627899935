import React from 'react'
import SideNav from '../Components/SideNav'
import InitiatedComp from '../Components/InitiatedComp'
import NewSide from '../Components/NewSide'

function Initiated() {
  return (
    <div>
     <NewSide/>
     <InitiatedComp/> 
    </div>
  )
}

export default Initiated
