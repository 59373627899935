import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Define styles
const mainStyle = {
  padding: '20px 30px',
  transition: 'all 0.3s',
  height: 'calc(100vh - 60px)', // Account for header height
  overflowY: 'auto',
  marginTop: '60px', // Add margin to account for fixed header
};

const style = {
  width: '100%',
  boxSizing: 'border-box',
};

const tableContainerStyle = {
  width: '100%',
  overflowX: 'auto',
  marginTop: '20px',
  maxHeight: 'calc(100vh - 300px)', // Leave space for header, filters and export buttons
  overflowY: 'auto',
};

const exportButtonsStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '1em',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
};

const filterContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '20px',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
  },
};

function TransHistoryComp() {
  const [histories, setHistories] = useState([]);
  const [filteredHistories, setFilteredHistories] = useState([]);
  const [error, setError] = useState('');
  const [clients, setClients] = useState({});
  const [agents, setAgents] = useState({});
  const [currencies, setCurrencies] = useState({});
  const [beneficiaries, setBeneficiaries] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  // Fetch transaction histories, clients, agents, currencies, and beneficiaries
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('admin_token');

        const [historyRes, clientsRes, agentsRes, currenciesRes, beneficiariesRes] = await Promise.all([
          axios.get('https://api.nkolozi.com/api/Admin/histories', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/clients', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/agents', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/currencies', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('https://api.nkolozi.com/api/Admin/get-beneficiaries', {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setHistories(historyRes.data.history);
        setFilteredHistories(historyRes.data.history);

        const mapData = (data, key = 'id') =>
          data.reduce((acc, item) => {
            acc[item[key]] = item.name || `${item.name} ${item.surname}` || item.full_name;
            return acc;
          }, {});

        setClients(mapData(clientsRes.data.data));
        setAgents(mapData(agentsRes.data.data));
        setCurrencies(mapData(currenciesRes.data.data));
        setBeneficiaries(mapData(beneficiariesRes.data.beneficiaries));
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setError(error.response?.data?.message || error.message || 'An unexpected error occurred');
      }
    };

    fetchData();
  }, []);

  // Helper function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Search function to filter table based on name and date
  const handleSearchAndFilter = () => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    const from = new Date(fromDate).getTime();
    const to = new Date(toDate).getTime();

    const filtered = histories.filter((history) => {
      const clientName = clients[history.client_id]?.toLowerCase() || '';
      const agentName = agents[history.agent_id]?.toLowerCase() || '';
      const transactionDate = new Date(history.created_at).getTime();

      const matchesSearch =
        clientName.includes(lowerSearchTerm) || agentName.includes(lowerSearchTerm);
      const matchesDate =
        (!fromDate || transactionDate >= from) && (!toDate || transactionDate <= to);

      return matchesSearch && matchesDate;
    });

    setFilteredHistories(filtered);
  };

  // Function to export table to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          'ID',
          'User Name',
          'Beneficiary ID',
          'Agent Name',
          'Client Name',
          'Currency',
          'Amount',
          'Type',
          'Created At',
          'Updated At',
        ],
      ],
      body: filteredHistories.map((history) => [
        history.id,
        clients[history.user_id] || 'Unknown User',
        // beneficiaries[history.beneficiary_id] || 'Unknown Beneficiary',
        agents[history.agent_id] || 'N/A',
        clients[history.client_id] || 'Unknown Client',
        currencies[history.currency_id] || 'Unknown Currency',
        history.amount,
        history.type,
        formatDate(history.created_at),
        formatDate(history.updated_at),
      ]),
    });
    doc.save('transaction_history.pdf');
  };

  // Function to export table to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredHistories.map((history) => ({
        ID: history.id,
        'User Name': clients[history.user_id] || 'Unknown User',
        // 'Beneficiary ID': beneficiaries[history.beneficiary_id] || 'Unknown Beneficiary',
        'Agent Name': agents[history.agent_id] || 'N/A',
        'Client Name': clients[history.client_id] || 'Unknown Client',
        Currency: currencies[history.currency_id] || 'Unknown Currency',
        Amount: history.amount,
        Type: history.type,
        'Created At': formatDate(history.created_at),
        'Updated At': formatDate(history.updated_at),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transaction History');
    XLSX.writeFile(workbook, 'transaction_history.xlsx');
  };

  return (
    <main id="main" className="main" style={mainStyle}>
      <div className="pagetitle">
        <h1>Transaction History</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body" style={{ padding: '20px' }}>
                {error && <Typography color="error">{error}</Typography>}
                
                {/* Search bar and date filters */}
                <div style={filterContainerStyle}>
                  <TextField
                    label="Search by Agent or Client Name"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    size="small"
                  />
                  <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    fullWidth
                    size="small"
                  />
                  <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    fullWidth
                    size="small"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearchAndFilter}
                    fullWidth
                  >
                    Filter
                  </Button>
                </div>

                {/* Export buttons */}
                <div style={exportButtonsStyle}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportToPDF}
                    fullWidth
                  >
                    Export to PDF
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={exportToExcel}
                    fullWidth
                  >
                    Export to Excel
                  </Button>
                </div>

                <TableContainer component={Paper} style={tableContainerStyle}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Beneficiary ID</TableCell>
                        <TableCell>Agent Name</TableCell>
                        <TableCell>Client Name</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredHistories.map((history) => (
                        <TableRow key={history.id}>
                          <TableCell>{agents[history.agent_id] || 'N/A'}</TableCell>
                          <TableCell>{clients[history.client_id] || 'Unknown Client'}</TableCell>
                          <TableCell>{currencies[history.currency_id] || 'Unknown Currency'}</TableCell>
                          <TableCell>{history.amount}</TableCell>
                          <TableCell>{history.type}</TableCell>
                          <TableCell>{formatDate(history.created_at)}</TableCell>
                          <TableCell>{formatDate(history.updated_at)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default TransHistoryComp;
