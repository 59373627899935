import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import AgentsComp from '../Components/AgentsComp'
import NewSide from '../Components/NewSide'

function Agents() {
  return (
    <>
    <NewSide/>
    <AgentsComp/>
    </>
  )
}

export default Agents
