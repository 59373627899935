import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import SuspendedTransComponent from '../Components/SuspendedTransComponent'
import NewSide from '../Components/NewSide'

function SuspendedTrans() {
  return (
    <div>
      
      <NewSide/>
      <SuspendedTransComponent/>
    </div>
  )
}

export default SuspendedTrans
