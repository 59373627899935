import React from 'react'
import SideNav from '../Components/SideNav'
import ClientsComp from '../Components/ClientsComp'
import NewSide from '../Components/NewSide'

function Clients() {
  return (
   <>
    <NewSide/>
    <ClientsComp/>
   </>
  )
}

export default Clients
