import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransStatusComponent from '../Components/TransStatusComponent'
import NewSide from '../Components/NewSide'

function TransSatus() {
  return (
    <div>
    
      <NewSide/>
      <TransStatusComponent/>
    </div>
  )
}

export default TransSatus
