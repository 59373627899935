import React from 'react'
import EmployeesComponent from '../Components/EmployeesComponents'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import NewSide from '../Components/NewSide'

function Employees() {
  return (
    <div>
      
      <NewSide/>
      <EmployeesComponent/>
    </div>
  )
}

export default Employees
