import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import UsersComponenent from '../Components/UsersComponent'
import NewSide from '../Components/NewSide'

function Users() {
  return (
    <>
      <NewSide/>
      <UsersComponenent/>
    </>
  )
}

export default Users
