import React from 'react'
import InnerBankComp from '../Components/InnerBankComp'
import SideNav from '../Components/SideNav' 
import NewSide from '../Components/NewSide'

function InnerBank() {
  return (
    <div>
      <NewSide/>
      <InnerBankComp/>
    </div>
  )
}

export default InnerBank
