import React from 'react'
import SideNav from '../Components/SideNav'
import RbzComp from '../Components/RbzComp'
import NewSide from '../Components/NewSide'

const Rbz = () => {
  return (
    <div>
        <NewSide/>
        <RbzComp/>
    </div>
  )
}

export default Rbz