import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';

const NewTop = () => {
  const [user, setUser] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    document.body.classList.toggle('toggle-sidebar');
  };

  const handleUserActivity = () => {
    const tokenExpiration = localStorage.getItem('token_expiration');
    if (tokenExpiration && new Date().getTime() > tokenExpiration) {
      localStorage.removeItem('admin_token');
      localStorage.removeItem('token_expiration');
      navigate('/login');
    }
  };
  
  useEffect(() => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
  
    return () => {
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [navigate]);

  useEffect(() => {
    const storedToken = localStorage.getItem('admin_token');
    if (!storedToken) {
      console.log("token not set");
      navigate('/login');
    } else {
      console.log(storedToken);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem('admin_token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Application-Type': 'application/json',
      };

      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/admin', { headers });
        setUser(response.data.data);
        console.log(response.data.data.name);
        if (response.data.data.status !== 'active' || response.data.data.user_type !== 'admin') {
          await handleLogout();
        }
      } catch (error) {
        setError(error.message || 'An error occurred while fetching admin details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem('admin_token');
    try {
      await fetch('https://api.nkolozi.com/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      localStorage.removeItem('admin_token');
      document.cookie = "admin_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      navigate('/login');
    } catch (error) {
      console.error('An error occurred during logout', error);
    }
  };

  return (
    <div>
      <header id="header" className="header fixed-top d-flex align-items-center" style={{ backgroundColor: '#b03a30' }}>
        <div className="d-flex align-items-center justify-content-between text-white">
          <a className="navbar-brand ps-3" href="/dashboard">
            {user.name} {user.surname}
          </a>
          <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar} />
        </div>
       
       
      </header>
      <Outlet/>
    </div>
  );
};

export default NewTop;
