import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import SettingsComponent from '../Components/SettingsComponent'
import NewSide from '../Components/NewSide'

function Settings() {
  return (
    <div>
     
     <NewSide/>
     <SettingsComponent/> 
    </div>
  )
}

export default Settings
