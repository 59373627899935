import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import TransHistoryComp from '../Components/TransHistoryComp'
import NewSide from '../Components/NewSide'

function TransHistory() {
  return (
    <div>
     
      <NewSide/>
      <TransHistoryComp/>
    </div>
  )
}

export default TransHistory
