import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import FeesComponent from '../Components/FeesComponent'
import NewSide from '../Components/NewSide'

function Fees() {
  return (
    <>
    
     <NewSide/>
     <FeesComponent/> 
    </>
  )
}

export default Fees
