import React from 'react';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box } from '@mui/system';

function AddComponent() {
  return (
    <main id="main" class="main">
    <Container maxWidth="sm" style={{ 
      marginTop: window.innerWidth <= 768 ? '20px' : '100%',
      padding: window.innerWidth <= 768 ? '15px' : '0'
    }}>
      <Box display="flex" 
        alignItems="center" 
        justifyContent="center" 
        mb={3}
        flexDirection={window.innerWidth <= 768 ? 'column' : 'row'}
        textAlign={window.innerWidth <= 768 ? 'center' : 'left'}
      >
        <PersonAddIcon style={{ 
          marginRight: window.innerWidth <= 768 ? '0' : '10px',
          marginBottom: window.innerWidth <= 768 ? '10px' : '0',
          fontSize: window.innerWidth <= 768 ? '2rem' : '2.5rem'
        }} />
        <Typography variant="h4" align="center" style={{
          fontSize: window.innerWidth <= 768 ? '1.5rem' : '2rem'
        }}>
          Add Agent
        </Typography>
      </Box>
      <form>
        <Grid container spacing={window.innerWidth <= 768 ? 2 : 3}>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Agent ID" 
              name="agentId" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Name" 
              name="name" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Surname" 
              name="surname" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="ID Number" 
              name="idNumber" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Phone" 
              name="phone" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Email" 
              name="email" 
              type="email" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Physical Address" 
              name="physicalAddress" 
              variant="outlined" 
              required 
              multiline
              rows={window.innerWidth <= 768 ? 2 : 3}
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Location" 
              name="location" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Branch" 
              name="branch" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Password" 
              name="password" 
              type="password" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField 
              fullWidth 
              label="Float Limit" 
              name="floatLimit" 
              variant="outlined" 
              required 
              InputProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
              InputLabelProps={{
                style: { fontSize: window.innerWidth <= 768 ? '14px' : '16px' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              type="submit"
              style={{
                padding: window.innerWidth <= 768 ? '8px' : '12px',
                fontSize: window.innerWidth <= 768 ? '14px' : '16px'
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
    </main>
  );
}

export default AddComponent;
