import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'
import AddComponent from '../Components/AddComponent'
import NewSide from '../Components/NewSide'

function Add() {
  return (
    <div>

<NewSide/>
<AddComponent/>
    </div>
  )
}

export default Add
