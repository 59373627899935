import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const NewSide = () => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.getElementById('sidebar');
      const toggleButton = document.querySelector('.toggle-sidebar-btn');
      
      // If clicking outside sidebar and not on toggle button, and sidebar is open (no toggle-sidebar class)
      if (sidebar && !sidebar.contains(event.target) && 
          toggleButton && !toggleButton.contains(event.target) &&
          document.body.classList.contains('toggle-sidebar')) {
        document.body.classList.remove('toggle-sidebar');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar" style={{ background: 'linear-gradient(135deg, #003366, #3366cc)' }}>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="bi bi-grid" />
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#transactions-nav" data-bs-toggle="collapse" href="#">
              <i className="bi bi-columns" /><span>Transactions</span><i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul id="transactions-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link to="/transactions">
                  <i className="bi bi-circle" /><span>Transactions</span>
                </Link>
              </li>
              <li>
                <Link to="/initiated">
                  <i className="bi bi-circle" /><span>Initiated Transactions</span>
                </Link>
              </li>
              <li>
                <Link to="/trans-hist">
                  <i className="bi bi-circle" /><span>Transaction History</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/clients">
              <i className="bi bi-people" />
              <span>Clients</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/agents">
              <i className="bi bi-person-badge" />
              <span>Agents</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/employees">
              <i className="bi bi-person-workspace" />
              <span>Employees</span>
            </Link>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#admin-nav" data-bs-toggle="collapse" href="#">
              <i className="bi bi-gear" /><span>System Administration</span><i className="bi bi-chevron-down ms-auto" />
            </a>
            <ul id="admin-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
              <li>
                <Link to="/sanction">
                  <i className="bi bi-circle" /><span>Sanctioned List</span>
                </Link>
              </li>
              <li>
                <Link to="/rbz">
                  <i className="bi bi-circle" /><span>RBZ</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/settings">
              <i className="bi bi-gear-fill" />
              <span>Settings</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/logout">
              <i className="bi bi-box-arrow-right" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )
}

export default NewSide
