import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Dropdown, Spinner, Alert, Form } from 'react-bootstrap';
import './ClientsComp.css';

function ClientsComp() {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState({ status: '', message: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [showTransactionsModal, setShowTransactionsModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://api.nkolozi.com/api/Admin/clients', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
          },
        });
        setClients(response.data.data); 
        setFilteredClients(response.data.data);
      } catch (error) {
        setError(error.message);
        console.error('There was an error fetching the clients!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('Clients state:', clients); 
  }, [clients]);

  const handleRowClick = (client) => {
    setSelectedClient(client);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowTransactionsModal(false);
    setSelectedClient(null);
  };

  const handleAction = async (clientId, action) => {
    const urls = {
      block: `https://api.nkolozi.com/api/Admin/block-user/${clientId}`,
      unblock: `https://api.nkolozi.com/api/Admin/un-block-user/${clientId}`,
      activate: `https://api.nkolozi.com/api/Admin/activate-client/${clientId}`,
      viewAccounts: `https://api.nkolozi.com/api/Admin/get-customer-transactions/${clientId}`
    };

    setLoading(true);
    try {
      if (action === 'viewAccounts') {
        const response = await axios.get(urls[action], {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
          },
        });
        setTransactions(response.data.customer_transactions);
        setShowTransactionsModal(true);
      } else {
        await axios.get(urls[action], {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
          },
        });
        const clientResponse = await axios.get('https://api.nkolozi.com/api/Admin/clients', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('admin_token')}`,
          },
        });
        setClients(clientResponse.data.data);
        setFilteredClients(clientResponse.data.data);
        setActionStatus({ status: 'success', message: `${action.charAt(0).toUpperCase() + action.slice(1)} action successful` });
      }
    } catch (error) {
      setActionStatus({ status: 'danger', message: `There was an error ${action} the client!` });
      setError(error.message);
      console.error(`There was an error ${action} the client!`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = clients.filter(client =>
      client.name.toLowerCase().includes(term) || client.surname.toLowerCase().includes(term)
    );
    setFilteredClients(filtered);
  };

  return (
    <main id="main" className="main">
      <h1>Clients</h1>
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      {actionStatus.message && (
        <Alert variant={actionStatus.status} onClose={() => setActionStatus({ status: '', message: '' })} dismissible>
          {actionStatus.message}
        </Alert>
      )}
      <Form.Control
        type="text"
        placeholder="Search by name or surname"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-3"
      />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="table-responsive" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Phone</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(filteredClients) && filteredClients.length > 0 ? (
                filteredClients.map(client => (
                  <tr key={client.id}>
                    <td onClick={() => handleRowClick(client)} style={{ cursor: 'pointer' }}>{client.id}</td>
                    <td onClick={() => handleRowClick(client)} style={{ cursor: 'pointer', color:"blue"}}>{`${client.name} ${client.surname}`}</td>
                    <td onClick={() => handleRowClick(client)} style={{ cursor: 'pointer' }}>{client.status}</td>
                    <td onClick={() => handleRowClick(client)} style={{ cursor: 'pointer' }}>{client.phone1}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleAction(client.id, 'block')}>Block</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleAction(client.id, 'unblock')}>Unblock</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleAction(client.id, 'activate')}>Activate</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleAction(client.id, 'viewAccounts')}>View Accounts</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No clients available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {selectedClient && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Client Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>ID:</strong> {selectedClient.id}</p>
            <p><strong>Registration ID:</strong> {selectedClient.reg_id}</p>
            <p><strong>Name:</strong> {`${selectedClient.name} ${selectedClient.surname} ${selectedClient.lastname}`}</p>
            <p><strong>Date of Birth:</strong> {selectedClient.dob}</p>
            <p><strong>Gender:</strong> {selectedClient.gender}</p>
            <p><strong>Phone 1:</strong> {selectedClient.phone1}</p>
            <p><strong>Phone 2:</strong> {selectedClient.phone2}</p>
            <p><strong>Email:</strong> {selectedClient.email}</p>
            <p><strong>National ID:</strong> {selectedClient.national_id}</p>
            <p><strong>City:</strong> {selectedClient.city}</p>
            <p><strong>Country:</strong> {selectedClient.country}</p>
            <p><strong>Location:</strong> {selectedClient.location}</p>
            <p><strong>Street Line:</strong> {selectedClient.street_line}</p>
            <p><strong>House Number:</strong> {selectedClient.house_number}</p>
            <p><strong>Bank Account:</strong> {selectedClient.bank_account}</p>
            <p><strong>User Type:</strong> {selectedClient.user_type}</p>
            <p><strong>Status:</strong> {selectedClient.status}</p>
            <p><strong>Account Type:</strong> {selectedClient.account_type}</p>
            <p><strong>Email Verified At:</strong> {selectedClient.email_verified_at}</p>
            <p><strong>Created At:</strong> {selectedClient.created_at}</p>
            <p><strong>Updated At:</strong> {selectedClient.updated_at}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showTransactionsModal && (
        <Modal show={showTransactionsModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Client Transactions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {transactions.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Transaction ID</th>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                    <th>Currency ID</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map(transaction => (
                    <tr key={transaction.id}>
                      <td>{transaction.transaction_id}</td>
                      <td>{transaction.initial_transfer_amount}</td>
                      <td>{transaction.transaction_fee}</td>
                      <td>{transaction.total_send_amount}</td>
                      <td>{transaction.status}</td>
                      <td>{transaction.currency_id}</td>
                      <td>{new Date(transaction.created_at).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No transactions found.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </main>
  );
}

export default ClientsComp;
