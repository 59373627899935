import React from 'react'
import Nav from '../Components/Nav'
import SideNav from '../Components/SideNav'

import HighRiskTransComponent from '../Components/HighRiskTransComponent'
import NewSide from '../Components/NewSide'

function HighRiskTrans() {
  return (
    <div>
      
      <NewSide/>
      <HighRiskTransComponent/>
    </div>
  )
}

export default HighRiskTrans
