import React from 'react'
import DepositsComp from '../Components/DepositsComp'
import SideNav from '../Components/SideNav'
import NewSide from '../Components/NewSide'

function Deposits() {
  return (
    <>
    <NewSide/>
    <DepositsComp/>
</>
  )
}

export default Deposits
